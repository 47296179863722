<template>
  <div>
    <b-table-simple
      hover
      caption-top
      caption-bottom
      responsive
      bordered
      small
    >
      <b-thead>
        <b-tr>
          <b-th
            class="font-small-2 text-nowrap align-middle"
          >
            STOK GRUBU
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            KRİTİK SEVİYE
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            GİREN MİKTAR
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            ÇIKAN MİKTAR
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            ADET
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            TUTAR
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item,key) in dataList">
          <b-tr
            :key="key"
          >
            <b-td class="font-small-2">
              <b-button
                variant="flat-success"
                class="btn-icon rounded-circle"
                @click="item.detailToggle = ! item.detailToggle"
              >
                <FeatherIcon
                  :icon="!item.detailToggle? 'EyeIcon' : 'EyeOffIcon'"
                />
              </b-button>
              {{ item.title }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.totals.criticals |toNumber }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.totals.entry_amount | toNumber }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.totals.output_amount | toNumber }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.totals.amount | toNumber }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.totals.priceValue | toCurrency }} TL
            </b-td>
          </b-tr>
          <b-tr
            v-if="item.detailToggle"
            :key="'detail_' + key"
          >
            <b-td
              colspan="8"
              class="m-0 p-0"
            >
              <stock-report-stock-table
                v-if="dataList[key].data.length >0"
                :i-key="key"
              />
              <b-alert
                v-else
                show
                variant="info"
                class="ml-2 mr-2 mt-2 text-center"
              >
                <div class="alert-body">
                  <FeatherIcon icon="InfoIcon" /> Veri bulunmuyor.
                </div>
              </b-alert>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BButton, BTd, BAlert,
} from 'bootstrap-vue'
import StockReportStockTable from '@/views/Admin/Reports/Stocks/Components/StockTable.vue'

export default {
  name: 'StockReportGroupTable',
  components: {
    StockReportStockTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BButton,
    BTd,
    BAlert,
  },
  computed: {
    dataList() {
      return this.$store.getters['stockReports/reportData']
    },
  },
}
</script>
