<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="filterParams.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            :disabled="loadingData"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="filterParams.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            :disabled="loadingData"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Proje"
          label-for="id_projects"
        >
          <v-select
            id="id_projects"
            v-model="filterParams.id_projects"
            :options="projects"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            :disabled="loadingData"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Depo"
          label-for="id_warehouses"
        >
          <v-select
            id="id_warehouses"
            v-model="filterParams.id_warehouses"
            :options="warehouses"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            :disabled="loadingData"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Fatura No"
          label-for="invoice_number"
        >
          <b-form-input
            v-model="filterParams.invoice_number"
            placeholder="Fatura No"
            :disabled="loadingData"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="primary"
            :disabled="loadingData"
            @click="getReport"
          >
            <b-spinner
              v-if="loadingData"
              small
            />
            <feather-icon
              v-if="!loadingData"
              icon="FilterIcon"
            /> Sonuçları Getir
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow, BCol, BCard, BFormDatepicker, BFormGroup, BFormInput, BSpinner, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'StockReportsFilterData',
  components: {
    BRow,
    BCol,
    BCard,
    BFormDatepicker,
    BFormGroup,
    vSelect,
    BFormInput,
    BSpinner,
    BButton,
  },
  props: {
    getReport: {
      type: Function,
      require: true,
    },
  },
  computed: {
    filterParams() {
      return this.$store.getters['stockReports/filterParams']
    },
    warehouses() {
      return this.$store.getters['warehouses/dataList']
    },
    projects() {
      return this.$store.getters['projects/dataList']
    },
    loadingData() {
      return this.$store.getters['stockReports/loadingData']
    },
  },
  created() {
    this.getWarehouses()
    this.getProjects()
  },
  methods: {
    getWarehouses() {
      this.$store.dispatch('warehouses/getDataList', {
        select: ['warehouses.id AS id', 'warehouses.title AS title'],
      })
    },
    getProjects() {
      this.$store.dispatch('projects/getDataList', {
        select: ['projects.id AS id', 'projects.title AS title'],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
