<template>
  <div>
    <b-table-simple
      hover
      caption-top
      caption-bottom
      responsive
      bordered
      small
    >
      <b-thead>
        <b-tr>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            STOK KODU
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle"
          >
            STOK ADI
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            Giren Miktar
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            Çıkan FİYAT
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            BİRİM FİYAT
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            ADET
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            TUTAR
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item,key) in dataList[iKey].data">
          <b-tr
            :key="key"
            :class="item.critical ? 'bg-light-danger text-white':''"
          >
            <b-td class="font-small-2">
              <b-button
                variant="flat-success"
                class="btn-icon rounded-circle"
                @click="item.detailToggle = ! item.detailToggle"
              >
                <FeatherIcon
                  :icon="!item.detailToggle? 'EyeIcon' : 'EyeOffIcon'"
                />
              </b-button>
              {{ item.scode }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.title }}
              <div>
                <b-badge
                  v-if="item.critical"
                  variant="light-danger"
                >
                  <feather-icon icon="AlertTriangleIcon" /> Stok Kritik Seviyede!
                </b-badge>
              </div>
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.entry_amount | toNumber }} {{ item.units }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.output_amount | toNumber }} {{ item.units }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.amountPrice | toCurrency }} TL
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.amount | toNumber }} /
              <b v-if="item.min_amount">
                {{ item.min_amount | toNumber }}
              </b>
              {{ item.units }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.priceValue | toCurrency }} TL
            </b-td>
          </b-tr>
          <b-tr
            v-if="item.detailToggle"
            :key="'detail_' + key"
          >
            <b-td
              colspan="8"
              class="m-0 p-0"
            >
              <stock-report-stock-lines-table
                :l-key="key"
                :i-key="iKey"
              />
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BButton, BTd, BBadge,
} from 'bootstrap-vue'
import StockReportStockLinesTable from '@/views/Admin/Reports/Stocks/Components/StockLinesTable.vue'

export default {
  name: 'StockReportStockTable',
  components: {
    StockReportStockLinesTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BButton,
    BTd,
    BBadge,
  },
  props: {
    iKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataList() {
      return this.$store.getters['stockReports/reportData']
    },
  },
}
</script>
