<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate text-nowrap">
              <h2 class="mb-25 font-weight-bolder">
                {{ dataList.entry_amount | toNumber }}
              </h2>
              <span>Giriş Adet</span>
            </div>
            <b-avatar
              variant="light-success"
              size="45"
            >
              <feather-icon
                size="21"
                icon="DownloadIcon"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate text-nowrap">
              <h2 class="mb-25 font-weight-bolder">
                {{ dataList.output_amount | toNumber }}
              </h2>
              <span>Çıkış Adet</span>
            </div>
            <b-avatar
              variant="light-danger"
              size="45"
            >
              <feather-icon
                size="21"
                icon="UploadIcon"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate text-nowrap">
              <h2 class="mb-25 font-weight-bolder">
                {{ dataList.entry_priceValue |toCurrency }} TL
              </h2>
              <span>Giriş Tutarı</span>
            </div>
            <b-avatar
              variant="light-danger"
              size="45"
            >
              <feather-icon
                size="21"
                icon="DollarSignIcon"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate text-nowrap">
              <h2 class="mb-25 font-weight-bolder">
                {{ dataList.output_priceValue | toCurrency }} TL
              </h2>
              <span>Çıkış Tutarı</span>
            </div>
            <b-avatar
              variant="light-success"
              size="45"
            >
              <feather-icon
                size="21"
                icon="DollarSignIcon"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate text-nowrap">
              <h2 class="mb-25 font-weight-bolder">
                {{ dataList.amount |toNumber }}
              </h2>
              <span>Stok Adedi</span>
            </div>
            <b-avatar
              variant="light-info"
              size="45"
            >
              <feather-icon
                size="21"
                icon="BoxIcon"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate text-nowrap">
              <h2 class="mb-25 font-weight-bolder">
                {{ dataList.criticals | toNumber }}
              </h2>
              <span>Kritik Stok Adedi</span>
            </div>
            <b-avatar
              variant="light-warning"
              size="45"
            >
              <feather-icon
                size="21"
                icon="AlertCircleIcon"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BAvatar, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'StockReportIstatistics',
  components: {
    BRow, BCol, BCard, BAvatar, BCardBody,
  },
  computed: {
    dataList() {
      return this.$store.getters['stockReports/totals']
    },
  },
}
</script>
