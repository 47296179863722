<template>
  <div>
    <b-table-simple
      hover
      caption-top
      caption-bottom
      responsive
      bordered
      small
    >
      <b-thead>
        <b-tr>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            TARİH
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            DEPO
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle"
          >
            PROJE
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            FATURA NO / BELGE NO
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            BİRİM FİYAT
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            Giriş Miktarı
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            Çıkış Miktarı
          </b-th>
          <b-th
            class="font-small-2 text-nowrap align-middle width-100 text-center"
          >
            TUTAR
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item,key) in dataList[iKey].data[lKey].lines">
          <b-tr
            :key="key"
            :class="item.entry_amount ? 'bg-light-success' : 'bg-light-secondary'"
          >
            <b-td class="font-small-2">
              {{ moment(item.created).format('DD.MM.YYYY') }}
            </b-td>
            <b-td class="font-small-2">
              {{ item.warehouses }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.projects ? item.projects : '-' }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.invoice_number }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.price | toCurrency }} TL
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.entry_amount | toNumber }} Adet
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.output_amount | toNumber }} Adet
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.priceValue | toCurrency }} TL
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd,
} from 'bootstrap-vue'

export default {
  name: 'StockReportStockLinesTable',
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
  },
  props: {
    iKey: {
      type: Number,
      required: true,
    },
    lKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataList() {
      return this.$store.getters['stockReports/reportData']
    },
  },
}
</script>
