<template>
  <div>
    <b-row>
      <b-col cols="12">
        <stock-reports-filter-data :get-report="getReport" />
      </b-col>
      <b-col
        v-if="!loadingData"
        cols="12"
      >
        <stock-report-istatistics />
        <b-card no-body>
          <b-card-body><h4>Stok Listesi</h4></b-card-body>
          <stock-report-group-table />
        </b-card>
      </b-col>
      <b-col
        v-else
        cols="12"
      >
        <pre-loading />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardBody,
} from 'bootstrap-vue'
import StockReportGroupTable from '@/views/Admin/Reports/Stocks/Components/GroupTable.vue'
import StockReportIstatistics from '@/views/Admin/Reports/Stocks/Components/Istatistics.vue'
import StockReportsFilterData from '@/views/Admin/Reports/Stocks/Components/FilterData.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'Index',
  components: {
    PreLoading,
    StockReportsFilterData,
    StockReportIstatistics,
    StockReportGroupTable,
    BRow,
    BCol,
    BCard,
    BCardBody,
  },
  data() {
    return {

    }
  },
  computed: {
    filterData() {
      return this.$store.getters['stockReports/filterParams']
    },
    loadingData() {
      return this.$store.getters['stockReports/loadingData']
    },
  },
  watch: {

  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.$store.dispatch('stockReports/getReport', this.filterData)
    },
  },
}
</script>
